





























































































































































// CORE
import { Component, Mixins } from 'vue-property-decorator'

// COMPONENTS
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import DefaultLayout from '@/components/layouts/DefaultLayout.vue'
import RequestFormPartWrapper from '@/components/RequestFormPartWrapper.vue'
import ControlsRequestFormPart from '@/components/forms/parts/ControlsRequestFormPart.vue'
import CarDrivingFormPart from '@/components/forms/parts/CarDrivingFormPart.vue'
import CaptionCard from '@/components/cards/CaptionCard.vue'

// INTERFACES
import { CarDrivingResource, CreateReplacementCarRequest, NameValueResource } from '@/store/types'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import FleetOrderModule from '@/store/modules/fleet/order'

@Component({
  components: {
    DefaultLayout,
    CaptionCard,
    ControlsRequestFormPart,
    RequestFormPartWrapper,
    CarDrivingFormPart,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class CarReplacementOrderCreate extends Mixins(SystemMixin, NotifyMixin) {
  private rules = {
    count: (value: string) => value.length <= 500,
  }

  private isShowCaption = true

  private date: string[] = []

  // FORM
  private form: CreateReplacementCarRequest = { // CreateReplacementCarRequest
    carId: 0,
    city: '',
    cityReturn: '',
    comment: '',
    paid: false,
    carClass: '',
    startDate: '',
    endDate: '',
    carDriving: {} as CarDrivingResource,
  }

  private isShowCarDriving = false
  private isShowComment = false

  private get carClasses (): NameValueResource[] {
    return FleetOrderModule.carClassesDictionary
  }

  private get cities (): string[] {
    return FleetOrderModule.serviceCitiesDictionary
  }

  private created () {
    FleetOrderModule.getOrderDictionaries({ entity: +this.entity, params: { hasRepair: false } })
  }

  private handleCloseCarDriving () {
    this.isShowCarDriving = false
    this.form.carDriving = {
      user: {
        name: '',
        surname: '',
        patronymic: '',
        email: '',
        personalPhone: '',
      },
      date: '',
      address: '',
    }
  }

  private handleCloseComment () {
    this.isShowComment = false
    this.form.comment = ''
  }

  private handleSubmit () {
    const form: any = this.$refs.form
    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          const body: any = {
            ...this.form,
            startDate: this.date[0],
            endDate: this.date[1],
            carId: this.form.carId ? this.form.carId : undefined,
            carClass: this.form.carClass ? this.form.carClass : undefined,
          }

          // if (!body.carDriving?.user?.email?.length) {
          //   body.carDriving = undefined
          // }

          FleetOrderModule.sendReplacementCar({ entity: +this.entity, body })
            .then((r) => {
              this.$openModal('OrderSendSuccessModal', 'big', {
                route: {
                  name: `view-order.${r.type}`,
                  params: { orderId: r.id },
                },
              })
              this.$router.push({ name: 'orders' })
            })
            .catch(() => {
              this.notifyError('Заявка не отправлена. Необходимо заполнить все обязательные поля.')
            })
        }
      })
  }
}
