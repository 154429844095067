




















// CORE
import { Component, Prop, Vue } from 'vue-property-decorator'

// INTERFACES

@Component
export default class ControlsRequestFormPart extends Vue {
  @Prop({ default: 'Отправить' })
  readonly sendText!: string
  private handleCancel () {
    this.$router.push({ name: 'orders' })
  }
}
