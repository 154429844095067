import { render, staticRenderFns } from "./ControlsRequestFormPart.vue?vue&type=template&id=274c35f5&scoped=true&lang=pug&"
import script from "./ControlsRequestFormPart.vue?vue&type=script&lang=ts&"
export * from "./ControlsRequestFormPart.vue?vue&type=script&lang=ts&"
import style0 from "./ControlsRequestFormPart.vue?vue&type=style&index=0&id=274c35f5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "274c35f5",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
