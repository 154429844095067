













































// CORE
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

// INTERFACES
import { ICarDrivindResource, UserAuthResource } from '@/store/types'

// COMPONENTS
import { ValidationProvider } from 'vee-validate'
import CharacterCard from '@/components/cards/CharacterCard.vue'

@Component({
  inheritAttrs: false,
  components: {
    CharacterCard,
    ValidationProvider,
  },
})
export default class CarDrivingFormPart extends Vue {
  @Prop({ default: 'Add' })
  readonly formPartId!: string | any

  @PropSync('data', { default: () => ({ address: '', date: '', user: {} }) })
  private innerForm!: ICarDrivindResource

  @Prop({ default: true })
  private isRequired!: boolean

  private mounted () {
    this.$bus.$on(`characterFormChange-${this.formPartId}`, (payload: UserAuthResource) => {
      this.innerForm = {
        ...this.innerForm,
        user: payload,
      }
    })
  }
}
